
import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Link, MenuItem, Paper, Select, TableRow, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';

export const StatWrapper = styled('div')(
    ({ theme }) => `
    background-color: ${theme.palette.background.paper};
    box-shadow: ${theme.shadows[1]};
    border-radius: ${theme.shape.borderRadius}px;
    padding: ${theme.spacing(2)};
    min-width: 300px;
  `,
);

// $brand-white: #ffffff;
// $brand-elephant: #cdc7cf;
// $brand-dusk: #4f566b;
// $brand-sienna: #e06a56;
// $brand-eggplant: #392330;
// $brand-black: #000000;
// $brand-blue: #52689e;
// $brand-green: #429d84;
// $brand-sky-blue: #e4ebfc;
// $homepage-light: #f9f9f9;
// $brand-light-green: #e9f1e8;


const styles = {
    formHelperText:
    {
        fontSize: ".75rem",
        lineHeight: "1.2rem",
        textAlign: "center"

    },
    formControlLabel:
    {
        color: "#4f566b",
    },
    greenFilledButton:
    {
        color: "#ffffff",
        backgroundColor: "#429d84",
        fontSize: ".8rem",
        fontWeight: "600",
        lineHeight: "1.2rem",
        border: "0px"
    },
    orangeOutlinedButton:
    {
        color: "#e06a56",
        backgroundColor: "#ffffff",
        fontSize: ".8rem",
        fontWeight: "600",
        lineHeight: "1.2rem",
        border: "1px solid #e06a56",
        '&:hover': {
            color: "#ffffff",
            backgroundColor: "#e06a56",
            border: "0px",
        },
    },
    linkButton:
    {
        color: '#52689E',
        fontWeight: '400',
        cursor: 'pointer',
        padding: '20px 20px 20px 0',
        '&:hover': {
            color: "#429d84"
        },
    },
    paper:
    {
        minHeight: '170px',
        padding: '30px',
        margin: '20px 0',
    },
    darkPaper:
    {
        backgroundColor: '#392330',
        color: "#FFFFFF",
        minHeight: '170px',
        padding: '30px',
        margin: '20px 0',
    },
    select:
    {
        minWidth: "10rem"
    }
};

//https://mui.com/material-ui/customization/how-to-customize/

const StyledSelect = styled(Select)(({ theme }) => ({
    '&.MuiInputBase-root': {
        color: theme.palette.warning.main,
        textAlign: "center",
        paddingLeft: "1rem",
    }

}));

const StyledText = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        color: theme.palette.warning.main,

    },

    "& input": //the space after & is important
    {
        textAlign: "center",
        "&::placeholder": {
            fontSize: "1rem"
        }
    },
    "& textarea": //the space after & is important
    {
        textAlign: "left",
        "&::placeholder": {
            fontSize: "1rem"
        }
    },
    "& .MuiFormHelperText-root":
    {
        fontSize: ".75rem",
        textAlign: "center"
    }

}));




export const NFormControl = (props) => <FormControl variant="standard" sx={styles.formControl}  {...props} />;
export const NSelect = (props) => <StyledSelect sx={styles.select} size="small" variant="standard" {...props} />;
export const NMenuItem = (props) => <MenuItem size="small" {...props} />;
export const NFormHelperText = (props) => <FormHelperText sx={styles.formHelperText}  {...props} />;
export const NText = (props) => <StyledText size="small" sx={{ width: '10ch' }} variant="standard" {...props} />; //this is 
//medium text, TODO://confirm best way to make width, confirm date text, confirm number
export const NFullWidthText = (props) => <StyledText fullWidth variant="standard" {...props} />; //this is 
//medium text, TODO://confirm best way to make width, confirm date text, confirm number
export const NLargeText = (props) => <StyledText size="small" sx={{ width: '25ch' }} variant="standard" {...props} />; //this is 
//medium text, TODO://confirm best way to make width, confirm date text, confirm number
export const NNumber = (props) => <StyledText size="small" type="number" sx={{ width: '10ch' }} variant="standard" {...props} /> //do we need this for number validation?
export const NMultiLineText = (props) => <StyledText multiline rows={5} variant="standard" {...props} />
export const NCheckbox = (props) => <Checkbox color="secondary" size="medium" sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }} {...props} />;
export const NFormControlLabel = (props) => <FormControlLabel sx={styles.formControlLabel} {...props} />
export const NDivider = (props) => <Divider {...props}></Divider>
export const NTableRow = (props) => <TableRow {...props}></TableRow>

export const NBox = (props) => <Box {...props}></Box>
export const NPaper = (props) => <Paper {...props} sx={styles.paper} />
export const NDarkPaper = (props) => <Paper {...props} sx={styles.darkPaper} />

export const NError = (props) => <FormLabel {...props} sx={{ backgroundColor: "warning.main", color: "background.default" }}></FormLabel>
export const NInfo = (props) => <><i className="fas fa-info"></i>{" "}<FormLabel {...props} sx={{
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.32rem',
    color: "primary.main",
    textDecorationLine: 'underline',
    textDecorationColor: "#e06a56" //TODO; to fix to theme color
}}></FormLabel></>

//buttons
export const NFilledGreenButton = (props) => <Button variant="contained" sx={styles.greenFilledButton} {...props}></Button>
export const NOutlinedOrangeButton = (props) => <Button variant="outlined" sx={styles.orangeOutlinedButton} {...props}></Button>
export const NLinkButton = (props) => <Link sx={styles.linkButton} {...props}></Link>

