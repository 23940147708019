import {
  ADD_LOAN_PAYMENT_NOTE,
  CREATE_SERVICE_ENROLLMENT,
  GET_EXECUTED_LOAN,
  GET_LOAN_PAYMENT,
  GET_LOAN_PAYMENTS,
  GET_LOAN_PAYMENT_NOTES,
  GET_SERVICE_ENROLLMENT
} from "./types";

export const initialState = {
  currentExecutedLoan: {},
  currentPayments: [],
  serviceEnrollment: {}
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case GET_EXECUTED_LOAN:
      return {
        ...state,
        currentPayments: [],
        serviceEnrollment: {},
        currentExecutedLoan: payload
      }
    case GET_LOAN_PAYMENTS:
      return {
        ...state,
        currentPayments: payload
      }
    case GET_LOAN_PAYMENT:
      var mergedPayments = state.currentPayments;
      var indexToReplace = mergedPayments.findIndex((element) => { return (element.id === payload.data.id) });
      mergedPayments[indexToReplace] = payload.data;
      return {
        ...state,
        currentPayments: mergedPayments
      }
    case GET_LOAN_PAYMENT_NOTES:
      return {
        ...state,
        loanNotes: payload
      }

    case GET_SERVICE_ENROLLMENT:
    case CREATE_SERVICE_ENROLLMENT:
      return {
        ...state,
        serviceEnrollment: payload
      };
    case ADD_LOAN_PAYMENT_NOTE:
    case CREATE_SERVICE_ENROLLMENT:
    default:
      return state;
  }
};

export default reducer;