import { getAuth, onAuthStateChanged, sendPasswordResetEmail, signInWithCustomToken, signOut } from "firebase/auth";
import { getBearer, getFreshAccessToken, getUser, signUp, signUpWithOAuth } from "../../apis/auth";
import { updateCustomer } from "../../apis/customers";
import { triggerGenericNotification } from "../notification/actions";
import { TRIGGER_NOTIFICATION } from "../notification/types";
import { selectUser, selectUserAuthToken } from "./selectors";
import {
  AUTHENTICATED, CREATE_USER, RESET_PASSWORD, SIGN_IN, SIGN_IN_ERROR,
  SIGN_IN_SUCCESS, SIGN_OUT,
  UNAUTHENTICATED,
  UPDATE_USER,
  UPDATE_USER_PROFILE
} from "./types";



export const updateUser = address => (dispatch, getState) => {
  const state = getState();
  const userAuthToken = selectUserAuthToken(state);
  const user = selectUser(state);
  return updateCustomer({
    address,
    email: user.email,
    firstName: user.firstName,
    id: user.id,
    lastName: user.lastName,
    userAuthToken,
  })
    .then(data => {
      dispatch({ type: UPDATE_USER, payload: data });
      return data;
    })
    .catch(error => {
      console.log("ERROR UPDATING THE CURRENT USER");
      triggerGenericNotification(dispatch, error);
    });
};

export const updateUserProfile = ({
  address,
  email,
  firstName,
  lastName,
  middleName,
  phone,
}) => (dispatch, getState) => {
  const state = getState();
  const userAuthToken = selectUserAuthToken(state);
  const id = selectUser(state).id;
  var emailLowerCase = email.toString().toLowerCase();
  return updateCustomer({
    address,
    email: emailLowerCase,
    firstName,
    id,
    lastName,
    middleName,
    phone,
    userAuthToken,
  })
    .then(data => {
      dispatch({ type: UPDATE_USER_PROFILE, payload: data });
      dispatch({
        type: TRIGGER_NOTIFICATION,
        payload: {
          message: "Successfully updated your profile.",
          error: false,
        },
      });
      return data;
    })
    .catch(error => {
      console.log("ERROR UPDATING THE CURRENT USER PROFILE");
      triggerGenericNotification(dispatch, error);
    });
};

export const signInUser = ({ email, password }) => (
  dispatch,
) => {
  dispatch({ type: SIGN_IN });
  var emailLowerCase = email.toString().toLowerCase();
  return getBearer({ email: emailLowerCase, password })
    .then(({ FBToken, BearerToken }) => {
      const auth = getAuth();
      signInWithCustomToken(auth, FBToken)
        .then(res => {
          if (res.user && res.user.emailVerified === false) {
            res.user.sendEmailVerification().then(() => { }); //TODO: this does not work
          }
          getUser({ userAuthToken: BearerToken }).then(user => {
            return dispatch({
              type: SIGN_IN_SUCCESS,
              payload: {
                userAuthToken: BearerToken,
                ...user,
                hasVerifiedEmail: res.user.emailVerified,
              },
            });
          });
        }).catch(error => {
          dispatch({ type: SIGN_IN_ERROR, payload: error });
          triggerGenericNotification(dispatch, error);
        });
    });
}


export const signOutUser = () => (dispatch) => {
  const auth = getAuth();
  signOut(auth);
  dispatch({ type: SIGN_OUT });
};

export const sendPasswordResetEmailForUser = email => (
  dispatch,
) => {
  const auth = getAuth();
  return sendPasswordResetEmail(auth, email)
    .then(() => {
      dispatch({
        type: TRIGGER_NOTIFICATION,
        payload: {
          message: "Password reset email sent. Please check your email.",
          error: false,
        },
      });
      return dispatch({ type: RESET_PASSWORD });
    });
};

export const signUpUser = ({
  customerId,
  email,
  firstName,
  lastName,
  password,
  reCAPTCHAToken
}) => (dispatch) => {
  var emailLowerCase = email.toString().toLowerCase();
  return signUp({
    email: emailLowerCase,
    firstName,
    lastName,
    password,
    customerId,
    reCAPTCHAToken
  })
    .then(data => {
      dispatch({ type: CREATE_USER, payload: data });
    })
    .catch(error => {
      triggerGenericNotification(dispatch, error);
    });
};

export const signUpUserWithOAuth = ({
  email,
  firstName,
  lastName,
  idToken,
  refreshToken
}) => (dispatch) => {
  var emailLowerCase = email.toString().toLowerCase();
  return signUpWithOAuth({
    email: emailLowerCase,
    firstName,
    lastName,
    idToken
  })
    .then(data => {
      getFreshAccessToken(refreshToken).then(refreshedToken => {
        dispatch({ type: CREATE_USER, payload: data });
        dispatch({ type: SIGN_IN, payload: data });
        dispatch({
          type: AUTHENTICATED,
          payload: {
            token: refreshedToken,
            email: data.email,
            uid: data.uid,
            auth: true,
          },
        });
        dispatch({
          type: SIGN_IN_SUCCESS,
          payload: {
            userAuthToken: `Bearer ${refreshedToken}`,
            ...data,
            hasVerifiedEmail: true,
          },
        });
        return data;
      });
    })
    .catch(error => {
      triggerGenericNotification(dispatch, error);
    });
};


export const observeUserAuth = () => (dispatch) => {
  const auth = getAuth();
  onAuthStateChanged(auth, user => {
    if ((!user) || (user == null)) {
      dispatch({ type: UNAUTHENTICATED });
      return;
    }

    user.getIdToken(true).then(token => {
      const googleProviderData = user.providerData.find(provider => provider.providerId === "google.com");
      if (googleProviderData) {
        let firstName = googleProviderData.displayName?.toString().split(" ")[0];
        if (firstName === undefined || firstName === null || firstName === "") {
          triggerGenericNotification(dispatch, { response: { status: 1000 } }) //don't have first name from google
          dispatch({ type: UNAUTHENTICATED });
          return;
        }
        let lastName = googleProviderData.displayName?.toString().split(" ")[1];
        if (lastName === undefined || lastName === null || lastName === "") {
          lastName = firstName;
        }
        dispatch(signUpUserWithOAuth({ email: googleProviderData.email, firstName, lastName, idToken: token, refreshToken: user.stsTokenManager.refreshToken }));
      }
      else {
        dispatch({
          type: AUTHENTICATED,
          payload: {
            token,
            email: user.email,
            uid: user.uid,
            auth: true,
            hasVerifiedEmail: user.emailVerified
          },
        });

        getUser({ userAuthToken: `Bearer ${token}` }).then(user => {
          return dispatch({
            type: SIGN_IN_SUCCESS,
            payload: { userAuthToken: `Bearer ${token}`, ...user },
          });
        }).catch(e => {
          return dispatch({
            type: UNAUTHENTICATED
          });
        });
      }
    });

    document.addEventListener(
      "visibilitychange",
      () => {
        if (document.hidden) {
          return;
        }
        const user = auth.currentUser;

        if (user) {
          user.getIdToken();
        }
      },
      false
    );
  });
}
