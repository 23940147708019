import {
  createLoanRequest,
  deleteLoanRequest,
  finalizeLoan,
  getLoanDocument,
  getLoanRequest,
  getLoanRequestsForUser,
  rejectLoanReason,
  reviewLoanRequest,
  sendLoanRequest,
  sendTermsOfService,
  signLoanRequest,
  updateLoanRequest
} from "../../apis/loans";


import { selectCurrentLoanRequest } from "../loans/selectors";
import { triggerGenericNotification } from "../notification/actions";
import { selectUserAuthToken } from "../user/selectors";
import {
  ACCEPT_TERMS_OF_SERVICE,
  CREATE_NEW_LOAN_REQUEST,
  DELETE_LOAN_REQUEST,
  FINALIZE_LOAN_REQUEST,
  FRESH_LOAN_REQUEST,
  GET_LOAN_DOCUMENT,
  GET_LOAN_REQUEST,
  GET_LOAN_REQUESTS,
  REJECT_REASON,
  REVIEWED_LOAN_REQUEST,
  SEND_LOAN_TO_RECIPIENT,
  SIGN_LOAN_REQUEST,
  UPDATE_LOAN_REQUEST
} from "./types";

export const getUserLoanRequests = ({ customerId }) => (dispatch, getState) => {
  const state = getState();
  const userAuthToken = selectUserAuthToken(state);
  getLoanRequestsForUser({ customerId, userAuthToken })
    .then(data => dispatch({ type: GET_LOAN_REQUESTS, payload: data }))
    .catch(error => {
      console.log("ERROR GETTING USER LOAN REQUESTS");
      triggerGenericNotification(dispatch, error);
    });
};

export const getUserLoanRequest = ({ id }) => (dispatch, getState) => {
  const state = getState();
  const userAuthToken = selectUserAuthToken(state);
  return getLoanRequest({
    loanRequestID: id,
    userAuthToken,
  })
    .then(data => {
      dispatch({ type: GET_LOAN_REQUEST, payload: data });
      return data;
    })
    .catch(error => {
      console.log("ERROR CREATING NEW LOAN REQUEST");
      triggerGenericNotification(dispatch, error);
    });
};

export const getLoanDocumentByID = ({ loanDocumentID }) => (dispatch, getState) => {
  const state = getState();
  const userAuthToken = selectUserAuthToken(state);
  return getLoanDocument({
    loanDocumentID: loanDocumentID,
    userAuthToken,
  })
    .then(data => {
      dispatch({ type: GET_LOAN_DOCUMENT, payload: data });
      return data;
    })
    .catch(error => {
      console.log("ERROR FETCHING LOAN DOCUMENT");
      triggerGenericNotification(dispatch, error);
    });
};

export const createUserLoanRequest = ({
  initiatorRequestDetails,
  loanProposalDetails,
  recipientCustomerId,
  recipientRequestDetails,
  requestType,
  status,
  parentLoanId,
}) => (dispatch, getState) => {
  const state = getState();
  const userAuthToken = selectUserAuthToken(state);
  return createLoanRequest({
    initiatingCustomerId: state.user.id,
    initiatorRequestDetails,
    loanProposalDetails,
    recipientCustomerId,
    recipientRequestDetails,
    requestType,
    status,
    parentLoanId,
    userAuthToken,
  })
    .then(data => {
      dispatch({ type: CREATE_NEW_LOAN_REQUEST, payload: data });
      return data;
    })
    .catch(error => {
      console.log("ERROR CREATING NEW LOAN REQUEST");
      triggerGenericNotification(dispatch, error);
    });
};

export const updateUserLoanRequestBasics = ({
  initiatorRequestDetails,
  loanProposalDetails,
  recipientRequestDetails,
}) => (dispatch, getState) => {
  const state = getState();
  const currentLoanRequest = selectCurrentLoanRequest(state);
  const userAuthToken = selectUserAuthToken(state);
  const joinedLoanDetails = {
    ...currentLoanRequest.loanProposalDetails,
    ...loanProposalDetails,
  };

  const joinedRecipientDetails = {
    ...currentLoanRequest.recipientRequestDetails,
    ...recipientRequestDetails,
  };

  return updateLoanRequest({
    loanDetails: {
      ...currentLoanRequest,
      initiatorRequestDetails,
      loanProposalDetails: joinedLoanDetails,
      recipientRequestDetails: joinedRecipientDetails,
    },
    userAuthToken,
  })
    .then(data => {
      dispatch({ type: UPDATE_LOAN_REQUEST, payload: data });
      return data;
    })
    .catch(error => {
      console.log("ERROR CREATING NEW LOAN");
      triggerGenericNotification(dispatch, error);
    });
};

export const updateUserLoanProposalRequest = ({ loanProposalDetails }) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const userAuthToken = selectUserAuthToken(state);
  const currentLoanRequest = selectCurrentLoanRequest(state);
  const joinedLoanDetails = {
    ...currentLoanRequest.loanProposalDetails,
    ...loanProposalDetails,
  };
  return updateLoanRequest({
    userAuthToken,
    loanDetails: { ...currentLoanRequest, loanProposalDetails: joinedLoanDetails },
  })
    .then(data => {
      dispatch({ type: UPDATE_LOAN_REQUEST, payload: data });
      return data;
    })
    .catch(error => {
      console.log("ERROR UPDATING USER LOAN REQUEST");
      triggerGenericNotification(dispatch, error);
    });
};

export const updateUserLoanRecipientRequest = ({ recipientRequestDetails }) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const userAuthToken = selectUserAuthToken(state);
  const currentLoanRequest = selectCurrentLoanRequest(state);
  const joinedLoanDetails = {
    ...currentLoanRequest.recipientRequestDetails,
    ...recipientRequestDetails,
  };
  return updateLoanRequest({
    userAuthToken,
    loanDetails: { ...currentLoanRequest, recipientRequestDetails: joinedLoanDetails },
  })
    .then(data => {
      dispatch({ type: UPDATE_LOAN_REQUEST, payload: data });
      return data;
    })
    .catch(error => {
      console.log("ERROR UPDATING USER LOAN REQUEST");
      triggerGenericNotification(dispatch, error);
    });
};

export const submitReviewLoanRequest = () => (dispatch, getState) => {
  const state = getState();
  const userAuthToken = selectUserAuthToken(state);
  const currentLoanRequest = selectCurrentLoanRequest(state);
  return reviewLoanRequest({
    userAuthToken,
    loanRequestId: currentLoanRequest.id,
  })
    .then(data => {
      dispatch({ type: REVIEWED_LOAN_REQUEST, payload: data });
      return data;
    })
    .catch(error => {
      console.log("ERROR UPDATING USER LOAN");
      triggerGenericNotification(dispatch, error);
    });
};

export const acceptTermsOfService = loanID => (dispatch, getState) => {
  const state = getState();
  const userAuthToken = selectUserAuthToken(state);
  return sendTermsOfService({
    userAuthToken,
    loanRequestId: loanID,
  })
    .then(data => {
      dispatch({ type: ACCEPT_TERMS_OF_SERVICE, payload: data });
      return data;
    })
    .catch(error => {
      console.log("ERROR ACCEPTING USER TERMS OF SERVICE");
      triggerGenericNotification(dispatch, error);
    });
};

export const sendLoanToParty = (loanID, whoUpdated) => (dispatch, getState) => {
  const state = getState();
  const userAuthToken = selectUserAuthToken(state);
  return sendLoanRequest({
    userAuthToken,
    loanRequestId: loanID,
    updateParty: whoUpdated,
  })
    .then(data => {
      dispatch({ type: SEND_LOAN_TO_RECIPIENT, payload: data });
      return data;
    })
    .catch(error => {
      console.log("ERROR SENDING LOAN TO THE RECIPIENT");
      triggerGenericNotification(dispatch, error);
    });
};

export const postFinalizeLoan = loanID => (dispatch, getState) => {
  const state = getState();
  const userAuthToken = selectUserAuthToken(state);
  return finalizeLoan({
    userAuthToken,
    loanRequestId: loanID,
  })
    .then(data => {
      dispatch({ type: FINALIZE_LOAN_REQUEST, payload: data });
      return data;
    })
    .catch(error => {
      console.log("ERROR FINALIZING LOAN");
      triggerGenericNotification(dispatch, error);
    });
};

export const postSignLoanRequest = ({ loanRequestId, signatureData }) => (dispatch, getState) => {
  const state = getState();
  const userAuthToken = selectUserAuthToken(state);
  return signLoanRequest({
    userAuthToken,
    loanRequestId: loanRequestId,
    signatureData: signatureData
  })
    .then(data => {
      dispatch({ type: SIGN_LOAN_REQUEST, payload: data });
      return data;
    })
    .catch(error => {
      console.log("ERROR SIGNING LOAN");
      triggerGenericNotification(dispatch, error);
    });
};

export const postRejectLoanReason = (loanID, rejectionReason) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const userAuthToken = selectUserAuthToken(state);
  return rejectLoanReason({
    userAuthToken,
    loanRequestId: loanID,
    rejectionReason,
  })
    .then(data => {
      dispatch({ type: REJECT_REASON, payload: data });
      return data;
    })
    .catch(error => {
      console.log("ERROR SENDING REJECT REASON");
      triggerGenericNotification(dispatch, error);
    });
};

export const resetCurrentLoanRequest = () => (dispatch) => {
  dispatch({ type: FRESH_LOAN_REQUEST });
}


export const deleteUserLoanRequest = ({ loanRequestId }) => (dispatch, getState) => {
  const state = getState();
  const userAuthToken = selectUserAuthToken(state);
  deleteLoanRequest({ loanRequestId, userAuthToken })
    .then(data => {
      if (state.loans.currentLoanRequest && (loanRequestId === state.loans.currentLoanRequest.id)) {
        dispatch({ type: DELETE_LOAN_REQUEST })
      }
    })
    .catch(error => {
      console.log("ERROR DELETING LOAN REQUEST", error);
      triggerGenericNotification(dispatch, error);
    });
};


