import {
  ACCEPT_TERMS_OF_SERVICE,
  CREATE_NEW_GIFT_REQUEST, DELETE_GIFT_REQUEST, FINALIZE_GIFT_REQUEST, FRESH_GIFT_REQUEST, GET_GIFT_DOCUMENT,
  GET_GIFT_REQUEST, GET_GIFT_REQUESTS, REJECT_REASON,
  REVIEWED_GIFT_REQUEST,
  SEND_GIFT_TO_RECIPIENT,
  SIGN_GIFT_REQUEST,
  UPDATE_GIFT_REQUEST
} from "./types";

export const currentGiftRequestInitialState = {
  giftDetails: {},
  donorRequestDetails: {}
};

export const initialState = {
  currentGiftRequest: currentGiftRequestInitialState,
  giftRequests: [],
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case GET_GIFT_REQUESTS:
      return {
        ...state,
        giftRequests: payload,
      };
    case FRESH_GIFT_REQUEST:
    case DELETE_GIFT_REQUEST:
      return {
        ...state,
        currentGiftRequest: currentGiftRequestInitialState,
      };
    case CREATE_NEW_GIFT_REQUEST:
      return {
        ...state,
        currentGiftRequest: payload
      };
    case UPDATE_GIFT_REQUEST:
      return {
        ...state,
        currentGiftRequest: payload
      };
    case SIGN_GIFT_REQUEST:
      return {
        ...state,
        currentGiftRequest: payload
      };
    case GET_GIFT_REQUEST:
      return {
        ...state,
        currentGiftRequest: payload
      };
    case ACCEPT_TERMS_OF_SERVICE:
    case FINALIZE_GIFT_REQUEST:
    case GET_GIFT_DOCUMENT:
    case REJECT_REASON:
    case REVIEWED_GIFT_REQUEST:
    case SEND_GIFT_TO_RECIPIENT:
    default:
      return state;
  }
};

export default reducer;